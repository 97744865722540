var messageBoxDuration = 2000;

function showMessageBox(type, message, callback=()=>{}) {
    var boxType = type;
    var content = '';
    var topMessage = ''
    if (boxType == 'danger') {
        topMessage = "ERROR"
    } else {
        topMessage = "SUCCESS" 
    }
    var messageBox = '<div class="alert-container fade in"><div class="alert-' + boxType + '"><img class="alert-image" src="/skins/current-skin/images/icons/' + boxType + '.svg"></img><div class="alert">'+topMessage+'!</div></div><div style="background: white;"><div class="alert-message">'+message+content+'</div></div></div>';
    $('div.alert-box').html(messageBox);
    setTimeout(function() {
        $('div.alert-box').html('');
        callback();
    }, messageBoxDuration);
}