$(document).ready(function () {

    var iframe = document.querySelector("iframe");

    if (iframe != null) {
        try {
            var player = new Vimeo.Player(iframe);
    
            player.on('play', function (data) {
                setTimeout(function () {
                    $('#pre-video').addClass('transition-out');
                    $('#home-video').addClass('transition-in');
                }, 200);
            });
    
            player.on("ended", function () {
                $('#pre-video').addClass('hide');
                $('#home-video').toggleClass('hide');
                $('#post-video').toggleClass('hide');
            });
        }
        catch {}
    }
});
